import React from "react";
import { PageProps } from "../../interfaces/projects";
import ProhectsHero from "../Shared/ProjectsHero";
import ProjectsHighlights from "../Shared/ProjectsHighlights";
import ProjectsAmenities from "../Shared/ProjectsAmenities";
import ProjectsUbicacion from "../Shared/ProjectsUbicacion";
import ProjectsSearchProperties from "../Shared/ProjectsSearchProperties";
import ProjectsFinanciacion from "../Shared/ProjectsFinanciacion";
import ProjectBrouchure from "../Shared/ProjectsBrouchure";

const PICaballitoPage: React.FC<PageProps> = ({
  colors,
  projectName,
  siteConfig,
}) => {
  return (
    <div className="picaballito-page">
      <ProhectsHero
        imgs={[
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646243266/Codevelopers/web/Pi%20Caballito/hero/1_header_PISCINA__ak8eov.jpg",
            label: "Piscina",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646243267/Codevelopers/web/Pi%20Caballito/hero/1b_TODO_LUZ__fj5qzb.jpg",
            label: "Luz",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646243266/Codevelopers/web/Pi%20Caballito/hero/1c_Cocinas_net__bzthok.jpg",
            label: "Cocinas<br/>net",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646243267/Codevelopers/web/Pi%20Caballito/hero/1C_Contempora%CC%81neo_hxrz60.jpg",
            label: "Contemporáneo",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646243267/Codevelopers/web/Pi%20Caballito/hero/1d_VISTAS_ABIERTAS__lkbctj.jpg",
            label: "Vistas<br/>abiertas",
          },
        ]}
      />
      <ProjectBrouchure
        title="UN HÁBITAT PARA<br/>UNA VIDA EN SANO<br />Y FELIZ EQUILIBRIO"
        subtitle="Unidades de 1 y 2 ambientes<br/> en el barrio Inglés de Caballito."
        brouchureSrc={
          "https://prismic-io.s3.amazonaws.com/codevelopers/cf7b087f-198c-4df9-914d-1a52dff83ff9_picaballitopresentafeb.pdf"
        }
        brouchureSrcMobile={""}
        colors={colors}
        images={{
          picture: "",
          main: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646248598/Codevelopers/web/Pi%20Caballito/brouchure/2_A_y39hxy_vmz6er.jpg",
          primary:
            "https://res.cloudinary.com/mosconi-digital/image/upload/v1646248598/Codevelopers/web/Pi%20Caballito/brouchure/2B_bl3vzk_ncts93.jpg",
          last: "",
          background: `/${projectName}/${projectName}-logo-simple-grey.svg`,
        }}
        templateImgs="two-horizontal"
      />
      <ProjectsSearchProperties
        colors={colors}
        project={projectName}
        images={[
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975709/Codevelopers/web/Pi%20Urquiza/Unidades/3A_UNIDADES_adduej.jpg",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975710/Codevelopers/web/Pi%20Urquiza/Unidades/3B_UNIDADES_tesr3e.jpg",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975710/Codevelopers/web/Pi%20Urquiza/Unidades/3C_UNIDADES_r2gcha.jpg",
          },
          {
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975710/Codevelopers/web/Pi%20Urquiza/Unidades/3D_UNIDADES_pqh8le.jpg",
          },
        ]}
        searchForm={{
          orientacion: {
            title: "Orientación",
            options: [
              {
                label: "Frente",
                value: "frente",
              },
              {
                label: "Contrafrente",
                value: "contrafrente",
              },
              {
                label: "Piso",
                value: "piso",
              },
              {
                label: "Todas las orientaciones",
                value: "all",
              },
            ],
          },

          exterior: {
            title: "Espacio exterior",
            options: [
              {
                label: "Balcón",
                value: "balcón",
              },
              {
                label: "Balcón terraza",
                value: "balcón terraza",
              },
              {
                label: "Terraza propia",
                value: "terraza propia",
              },
              {
                label: "Todas los espacios",
                value: "all",
              },
            ],
          },

          ambientes: {
            title: "Cantidad de ambientes",
            options: [
              {
                label: "3 Ambientes",
                value: "3",
              },
              {
                label: "4 Ambientes",
                value: "4",
              },
              {
                label: "Todos los ambientes",
                value: "all",
              },
            ],
          },
        }}
      />
      <ProjectsAmenities
        title={
          "LOS AMENITIES Y ESA PLACENTERA SENSACIÓN<br />DE ESTAR DONDE UNO QUIERE ESTAR"
        }
        amenities={[
          {
            icon: "terraza-verde",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1646680841/Codevelopers/web/Pi%20Urquiza/Amenities/IMAGENESWEBurquiza2_Mesa_de_trabajo_1_copia_2_umwgg1.jpg",
            title: "Terraza verde",
            description:
              "Les presentamos el punto más alto de la colmena y el disfrute donde además abunda el verde. La vegetación rodea la pileta de los chicos y el jacuzzi para disfrutar envueltos en naturaleza e intimidad. Deck, duchas y paz. Amén.",
          },
          {
            icon: "piscina",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1646680841/Codevelopers/web/Pi%20Urquiza/Amenities/IMAGENESWEBurquiza2_Mesa_de_trabajo_1_copia_wzgxxg.jpg",
            title: "Piscina<br />para niños",
            description:
              "Los más chicos también tienen sus espacios para el disfrute en Pī Urquiza. Juegos, chapuzones y risas, la postal de un verano ideal en la ciudad.",
            // objectPosition: "left",
          },
          {
            icon: "jacuzzi",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1646680839/Codevelopers/web/Pi%20Urquiza/Amenities/IMAGENESWEBurquiza2_Mesa_de_trabajo_1_mv3szv.jpg",
            title: "Jacuzzi<br />para grandes",
            description:
              "Mirar desde arriba, no desde el lugar de soberbia sino desde el relax. Momentos para uno mismo en el punto más alto de Pi Urquiza.",
            objectPosition: "right",
          },
          {
            icon: "huerta",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1645744343/Codevelopers/web/Pi%20Urquiza/Amenities/huerta_qj5xth.png",
            title: "Huerta<br />comunitaria",
            description:
              "A la huerta la reservamos para la terraza de uso común, porque lo compartido sabe más rico. Una alimentación más consciente, es parte de la experiencia wellness que Pī trae para vos.",
          },

          {
            icon: "estacionamiento-bicicleta",
            image:
              "https://res.cloudinary.com/mosconi-digital/image/upload/v1645744590/Codevelopers/web/Pi%20Urquiza/Amenities/estacionamiento.bicicleta_jnoeuh.jpg",
            title: "Estacionamiento<br />para bicicleta",
            description:
              "La movilidad sustentable es un must en el estilo de vida que buscás, y en Pī nada de eso falta. Todo listo para los Bicilovers que eligen el transporte más eco-friendly.",
          },
        ]}
        colors={colors}
      />
      <ProjectsHighlights
        colors={colors}
        items={[
          {
            title: "SUSTENTABILIDAD<br />Y ECO RIEGO",
            description:
              "Como fans de nuestro planeta diseñamos los espacios con materiales de bajo impacto ambiental en todo el edificio. Instalamos energías renovables en espacios comunes, purificadores de agua en cocinas y duchas, estacionamiento de bicis y cocheras con cargadores para autos eléctricos.<br />Los balcones se rebalsan de verdes con la complicidad de riegos automáticos. Acompañan las composteras individuales apostando a una conciencia y mundo más puro.",
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1644975569/Codevelopers/web/Pi%20Urquiza/Highlights/5_PRIMER_CI%CC%81RCULO_qhngyv.jpg",
            sustainableDevelopment: false,
            airbnb: false,
          },
          {
            title: "HOGAR,<br />INTELIGENTE HOGAR",
            description:
              "Todas nuestras unidades tienen apertura de cortinas automáticas, cerraduras electrónicas y climatización con sistema de aire acondicionado VRV.<br />El touch: Vidrios DVH con aislación térmica y acústica para poder estar solos en comunidad. Al fin de cuentas, el vivir bien también es una decisión inteligente.",
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646233880/Codevelopers/web/Pi%20Urquiza/Highlights/5_segundo_ci%CC%81rculo_r9mpkz.jpg",
            sustainableDevelopment: false,
            airbnb: false,
          },
          {
            title: "BAÑO SMART",
            description:
              "La magia de pisar calentito cuando salís de la ducha no tiene precio. Le decimos sí a la losa radiante y sí a envolverte en el toallón recién salido del secador de toallas eléctrico. Todos nuestros baños son inteligentes. Incluyen desde inodoros smart hasta espejos con desempañador automático. Espejito espejito, dime cuál es el baño más bonito.",
            img: "https://res.cloudinary.com/mosconi-digital/image/upload/v1646239411/Codevelopers/web/Pi%20Urquiza/Highlights/5_CIRCULO_3_hyib0p.jpg",
            sustainableDevelopment: false,
            airbnb: false,
          },
        ]}
      />
      <ProjectsUbicacion
        title="UN BARRIO DE<br />CALLES EMPEDRADAS Y RESTÓS"
        description="Rodeado de veredas con historia y esquinas con encanto propio,<br />Pi Caballito se ubica a metros del polo gastronómico<br />de Pedro Goyena y muy cerca de la línea A de subte."
        colors={colors}
        image={
          "https://res.cloudinary.com/mosconi-digital/image/upload/v1646243622/Codevelopers/web/Pi%20Caballito/ubicacion/UBICACIO%CC%81N_kwisrd.jpg"
        }
        location="Hualfin 1195, Caballito, Buenos Aires"
        geo={{
          lat: -34.6238197347682,
          lng: -58.444702531070504,
        }}
      />
      <ProjectsFinanciacion
        title="SUITES FINANCIADAS <br /> EN HASTA 60 CUOTAS"
        description="Pensamos en unidades de 2, 3 y 4 ambientes<br />totalmente equipadas con un único fin: no pensar en nada.<br />Todo listo para una nueva vida frente al mar."
        colors={colors}
        image={
          "https://res.cloudinary.com/mosconi-digital/image/upload/v1635895643/Codevelopers/web/AZURE/AZURE_-_4_tvt3rx.jpg"
        }
      />
    </div>
  );
};

export default PICaballitoPage;
